








































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { FunnelType } from "@/funnels/models/FunnelModel";
import { CreateFunnelSegmentModel } from "@/funnels/models/FunnelRequestModel";
import SegmentModel from "@/segments/models/SegmentModel";
import { AccessType } from "@/shared/models";

@Component
export default class FunnelSegment extends Vue {
  @Prop() value!: CreateFunnelSegmentModel;
  @Prop() type!: FunnelType;
  @Prop() steps!: Array<number>;

  get accessTypes(): Array<{ name: string; value: AccessType }> {
    return Object.values(AccessType).map((it) => {
      return {
        name: this.$lang(`accessType.${it.toString().toLowerCase()}`),
        value: it,
      };
    });
  }

  get stepsTo() {
    return this.stepNames.filter((it) => it.value >= this.value.stepFrom);
  }

  get stepNames() {
    return this.steps.map((it) => {
      return {
        name: this.$lang("funnel.step.number", it),
        value: it,
      };
    });
  }

  get conversion() {
    return [
      {
        name: this.$lang("funnel.segment.conversion.converted"),
        value: true,
      },
      {
        name: this.$lang("funnel.segment.conversion.notConverted"),
        value: false,
      },
    ];
  }

  get isUserConversion(): boolean {
    return this.type === FunnelType.USER_CONVERSION;
  }

  get isSegmentNameUnique(): boolean {
    return this.$store.state.segmentStore.isSegmentNameUnique;
  }

  get nameErrorMessages(): Array<string> {
    if (!this.isSegmentNameUnique) {
      return [this.$lang("shared.errors.notUnique")];
    }
    if (this.value.name?.trim() === "") {
      return [this.$lang("shared.errors.required")];
    }
    if (this.value.name && this.value.name.length > 50) {
      return [this.$lang("shared.errors.maxCharacters", 50)];
    }
    if (
      this.value.name?.endsWith(SegmentModel.AB_TEST_CONTROL_SEGMENT_POSTFIX)
    ) {
      return [
        this.$lang(
          "shared.errors.shouldntEndsWith",
          SegmentModel.AB_TEST_CONTROL_SEGMENT_POSTFIX
        ),
      ];
    }

    return [];
  }

  get descriptionErrorMessages(): Array<string> {
    if (this.value.description && this.value.description.length > 255) {
      return [this.$lang("shared.errors.maxCharacters", 255)];
    }

    return [];
  }

  @Watch("value.stepFrom")
  private watchStepFrom(stepFrom: number) {
    if (stepFrom > this.value.stepTo) {
      this.value.stepTo = stepFrom;
    }
  }

  checkIsSegmentNameUnique() {
    const segment = new SegmentModel(this.value.applicationId, this.value.name);
    this.$store.dispatch("checkIsSegmentNameUnique", segment);
  }
}
