




























































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import Chart from "@/chart/components/Chart.vue";
import FunnelChartModel, {
  FunnelChartMode,
  FunnelChartPercentMode,
} from "@/funnels/models/FunnelChartModel";
import { FunnelType, FunnelStepModel } from "@/funnels/models/FunnelModel";
import { Dictionary, DictionaryType, FilterId } from "@/shared/models";
import DateUtil from "@/shared/utils/DateUtil";
import { ChartName } from "@/chart/models/ChartModel";
import { getFunnelChartData } from "@/chart/utils/ChartUtil";

@Component({
  components: {
    Chart,
  },
})
export default class FunnelCharts extends Vue {
  @Prop() value!: Array<FunnelChartModel>;
  @Prop() type!: FunnelType;
  @Prop() applicationId!: string;
  @Prop({ default: FunnelChartMode.FUNNEL }) mode!: FunnelChartMode;
  @Prop() percentMode!: FunnelChartPercentMode;
  @Prop({ default: () => [] }) steps!: Array<FunnelStepModel>;
  @Prop() height!: string;

  limit = 10;

  get chartData(): Array<Array<Record<string, any>>> {
    const funnelChartData = this.$store.state.funnelStore.funnelChartData;

    return funnelChartData
      ? getFunnelChartData(funnelChartData, this.percentMode)
      : [];
  }

  get visibleChartData(): Array<Array<Record<string, any>>> {
    return this.chartData.slice(1, this.limit);
  }

  get chartStyle(): string {
    return this.chartData.length > 1
      ? "width: 338px; scroll-snap-align: start"
      : "width: 100%";
  }

  get isLoading(): boolean {
    return this.$store.state.funnelStore.loadingFunnelChart;
  }

  get segmentDictionaries(): Array<Dictionary> {
    return this.$store.state.dictionaryStore[DictionaryType.SEGMENTS].values;
  }

  get chartName(): ChartName | null {
    switch (this.mode) {
      case FunnelChartMode.FUNNEL:
        return this.type === FunnelType.REPEATED_CONVERSION
          ? ChartName.REPEATED_CONVERSION_FUNNEL_CHART
          : ChartName.FUNNEL_CHART;
      case FunnelChartMode.COLUMN:
        return this.type === FunnelType.REPEATED_CONVERSION
          ? ChartName.REPEATED_CONVERSION_FUNNEL_COLUMN_CHART
          : ChartName.FUNNEL_COLUMN_CHART;
      default:
        return null;
    }
  }

  @Watch("value")
  @Watch("mode")
  resetLimit() {
    this.limit = 10;
  }

  created() {
    if (!this.segmentDictionaries.length) {
      this.$store.dispatch("loadDictionaries", {
        app: this.applicationId,
        dictionaryTypes: [DictionaryType.SEGMENTS],
      });
    }
  }

  loadMore() {
    this.limit += 5;
    if (this.limit > this.value.length) {
      this.limit = this.value.length;
    }
  }

  chartTitle(breakdownValue: Map<FilterId, any>) {
    return Array.from(breakdownValue)
      .map(([id, value]) => {
        if (id === FilterId.SEGMENT) {
          return this.$store.getters.segmentsNameByValue[value] ?? value;
        }

        if (id === FilterId.ATTRIBUTION_DATE_VALUE) {
          return DateUtil.formatDate(value);
        }

        return value;
      })
      .join("_");
  }
}
