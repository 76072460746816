
































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import FunnelStepsPreviewFilter from "@/funnels/components/FunnelStepsPreviewFilter.vue";
import { FunnelStepModel } from "@/funnels/models/FunnelModel";
import {
  Dictionary,
  FILTER_ID_TO_DATA_TYPES,
  FilterModel,
} from "@/shared/models";

@Component({
  components: {
    FunnelStepsPreviewFilter,
  },
})
export default class FunnelStepsPreview extends Vue {
  @Prop() steps!: Array<FunnelStepModel>;
  @Prop({ default: true }) stepsVisible!: boolean;

  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }

  get applicationId(): string {
    return this.$store.state.application.applicationId;
  }

  @Watch("steps", { immediate: true })
  watchSteps(steps: Array<FunnelStepModel>) {
    if (!steps.length) {
      return;
    }

    const dictionaryTypes = steps.reduce(
      (result: Array<Dictionary>, step: FunnelStepModel) => {
        step.filter.forEach((filter: FilterModel) => {
          FILTER_ID_TO_DATA_TYPES[filter.id].forEach((dictionaryType: any) => {
            if (
              result.includes(dictionaryType) ||
              this.$store.state.dictionaryStore[dictionaryType].values.length
            ) {
              return;
            }

            result.push(dictionaryType);
          });
        });

        return result;
      },
      []
    );

    if (dictionaryTypes.length) {
      this.$store.dispatch("loadDictionaries", {
        app: this.applicationId,
        dictionaryTypes,
      });
    }
  }
}
