




















































































































































































































































































































































































































































































































































































































import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import stringify from "csv-stringify/lib/sync";

import FunnelCharts from "@/funnels/components/FunnelCharts.vue";
import FunnelSegment from "@/funnels/components/FunnelSegment.vue";
import Filters from "@/shared/components/filters/Filters.vue";
import ReportFilterCurrentState from "@/reports/components/ReportFilterCurrentState.vue";
import CustomAutocomplete from "@/shared/components/pickers/CustomAutocomplete.vue";
import FunnelStepsPreview from "@/funnels/components/FunnelStepsPreview.vue";
import NewFunnelTemplate from "@/funnels/components/NewFunnelTemplate.vue";
import FunnelModel, {
  FunnelType,
  FunnelStepModel,
} from "@/funnels/models/FunnelModel";
import FunnelChartModel, {
  FunnelChartTableHeaderDataModel,
  FunnelChartDataTableModel,
  FunnelChartMode,
  FunnelChartPercentMode,
} from "@/funnels/models/FunnelChartModel";
import {
  CreateFunnelSegmentModel,
  GetFunnelChartRequestModel,
  GetFunnelRequestModel,
} from "@/funnels/models/FunnelRequestModel";
import {
  FilterModel,
  FILTER_ID_TO_CHILDREN,
  singleValueToFilterModel,
  Application,
  FilterId,
  FilterPreview,
  FilterPreviewId,
  AppSection,
} from "@/shared/models";
import DateUtil from "@/shared/utils/DateUtil";
import FileUtil from "@/shared/utils/FileUtil";
import {
  calculateFirstStepPercent,
  calculateRelativePercent,
} from "@/funnels/utils/FunnelUtil";

@Component({
  components: {
    Filters,
    FunnelCharts,
    FunnelSegment,
    ReportFilterCurrentState,
    CustomAutocomplete,
    FunnelStepsPreview,
    NewFunnelTemplate,
  },
})
export default class FunnelChartView extends Vue {
  @Ref("funnelChartHeader") funnelChartHeader!: any;
  @Ref("mainFiltersPreviews") mainFiltersPreviews!: any;

  readonly chartModes = Object.values(FunnelChartMode).map((it) => ({
    name: this.$lang(`funnel.chart.modes.${it.toString().toLowerCase()}`),
    value: it,
  }));
  readonly FilterPreviewId = FilterPreviewId;
  readonly percentModes = [
    FunnelChartPercentMode.RELATIVE,
    FunnelChartPercentMode.FIRST_STEP,
  ].map((it) => {
    return {
      name: this.$lang(
        `funnel.chart.percentModes.${it.toString().toLowerCase()}`
      ),
      value: it,
    };
  });

  localValue: GetFunnelChartRequestModel = new GetFunnelChartRequestModel(
    this.applicationId,
    this.funnelId
  );
  localFunnel: FunnelModel = new FunnelModel(this.applicationId);
  localSegment: CreateFunnelSegmentModel = new CreateFunnelSegmentModel(
    this.applicationId,
    this.funnelId
  );
  localFilters: Array<FilterModel> = [];
  chartMode: FunnelChartMode = FunnelChartMode.COLUMN;
  chartPercentMode: FunnelChartPercentMode = FunnelChartPercentMode.RELATIVE;
  groupByPreview: FilterPreview | null = null;
  createSegmentDialog: any = {
    value: false,
    steps: [],
    breakdownName: "",
  };
  search = "";
  vResponsiveLayoutHeight = "0";
  funnelChartHeight = "0";
  itemsForExport: Array<FunnelChartDataTableModel> = [];
  isSidebarVisible = false;
  isSidebarPinned = false;
  isNewFunnelTemplateDialogVisible = false;
  stepsVisible = true;
  showDescriptionSection = false;

  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }

  get isTableMode(): boolean {
    return this.chartMode === FunnelChartMode.TABLE;
  }

  get headers(): Array<FunnelChartTableHeaderDataModel> {
    return [
      {
        text: "Breakdown value",
        align: "start",
        value: "name",
        width: "350px",
      },
      ...this.getHeadersForSteps(this.chartPercentMode),
    ];
  }

  get items(): Array<FunnelChartDataTableModel> {
    return [...this.getItemsForSteps(this.chartPercentMode)];
  }

  get csvHeaders(): Array<any> {
    return this.headers.map((it) => ({ key: it.value, header: it.text }));
  }

  get isLoadingFunnelChart(): boolean {
    return this.$store.state.funnelStore.loadingFunnelChart;
  }

  get applicationId(): string {
    return this.$store.state.application.applicationId;
  }

  get applicationName(): string {
    const application = (
      this.$store.state.application.apps as Array<Application>
    ).find((it) => it.value === this.applicationId);
    return application ? application.name : this.applicationId;
  }

  get funnelId(): number {
    return Number.parseInt(this.$route.params.funnelId);
  }

  get funnel(): FunnelModel {
    return this.$store.state.funnelStore.funnel;
  }

  get charts(): Array<FunnelChartModel> {
    return this.$store.state.funnelStore.funnelChartData
      ? this.$store.state.funnelStore.funnelChartData.data
      : [];
  }

  get generateDisabled(): boolean {
    return this.localValue.filter.some((it) => !it.valid);
  }

  get isSegmentNameUnique(): boolean {
    return this.$store.state.segmentStore.isSegmentNameUnique;
  }

  get createSegmentDisabled(): boolean {
    return (
      !this.localSegment ||
      !this.localSegment.applicationId ||
      !this.localSegment.funnelId ||
      !this.localSegment.name ||
      !this.isSegmentNameUnique ||
      this.localSegment.name.length > 50 ||
      (this.localSegment.description &&
        this.localSegment.description.length > 255) ||
      this.localSegment.stepFrom == undefined ||
      this.localSegment.stepTo == undefined ||
      this.localSegment.stepFrom > this.localSegment.stepTo ||
      this.localSegment.converted == undefined ||
      !this.localSegment.accessType
    );
  }

  get breakdowns() {
    return this.localFunnel.breakdowns
      .flatMap((it) => {
        const children = FILTER_ID_TO_CHILDREN[it];
        return children.length ? children : [it];
      })
      .map((it) => {
        return {
          name: this.$lang(`shared.filters.${it.toString().toLowerCase()}`),
          value: it,
        };
      });
  }

  get isUserConversion() {
    return this.localFunnel.type === FunnelType.USER_CONVERSION;
  }

  get isRepeatedConversion(): boolean {
    return this.localFunnel.type === FunnelType.REPEATED_CONVERSION;
  }

  get filterPreviews(): Array<FilterPreview> {
    return this.localFunnel.previews;
  }

  get localFiltersPreviews(): Array<FilterPreview> {
    const previews = [];

    if (this.groupByPreview) {
      previews.push(this.groupByPreview);
    }

    previews.push(
      ...this.localFilters.reduce(
        (previews: Array<FilterPreview>, filter: FilterModel) => {
          if (Array.isArray(filter.preview)) {
            previews.push(...filter.preview);
          } else {
            previews.push(filter.preview);
          }

          return previews;
        },
        []
      )
    );

    return previews;
  }

  get clickedFilterPreviewId(): FilterPreviewId {
    return this.$store.state.reportStore.clickedFilterPreviewId;
  }

  get hasFunnelSegmentsCreateAccess(): boolean {
    return this.$store.state.userStore.currentUser.createAccessEntities[
      this.applicationId
    ].includes(AppSection.FUNNEL_SEGMENTS);
  }

  get hasFunnelTemplatesCreateAccess(): boolean {
    return this.$store.state.userStore.currentUser.createAccessEntities[
      this.applicationId
    ].includes(AppSection.FUNNEL_TEMPLATES);
  }

  @Watch("clickedFilterPreviewId")
  watchPreviewClick() {
    this.isSidebarVisible = true;
  }

  @Watch("funnel", { deep: true })
  watchFunnel(funnel: FunnelModel) {
    this.localFunnel = FunnelModel.of(funnel);
    if (!this.isUserConversion) {
      this.chartPercentMode = FunnelChartPercentMode.TOTAL;
    }
  }

  @Watch("showDescriptionSection")
  watchShowDescriptionSection() {
    this.calcDynamicHeights();
  }

  @Watch("applicationId")
  watchApplicationId() {
    this.$router.push({
      name: AppSection.FUNNELS,
    });
  }

  async created() {
    await this.$store.dispatch(
      "getFunnel",
      new GetFunnelRequestModel(this.funnelId, this.applicationId)
    );

    this.$store.dispatch("loadFunnelDictionaries", {
      app: this.applicationId,
      funnel: this.funnel,
    });

    document.title = this.$lang(
      "documentTitle",
      this.$lang("funnel.chartTitle", this.funnel.name ?? "")
    );
    this.generate();
    this.calcDynamicHeights();
  }

  async calcDynamicHeights() {
    await this.$nextTick();
    // 64px - header height

    this.vResponsiveLayoutHeight = `calc(100vh - 64px - ${this.funnelChartHeader.$el.offsetHeight}px)`;
    // 61px - padding top and bottom (24px) + divider height (1px) + chatTitle section height (36px)
    this.funnelChartHeight = `calc(100vh - 64px - ${this.funnelChartHeader.$el.offsetHeight}px - ${this.mainFiltersPreviews.offsetHeight}px - 61px)`;
  }

  getHeadersForSteps(
    chartPercentMode: FunnelChartPercentMode
  ): Array<FunnelChartTableHeaderDataModel> {
    return this.localFunnel.steps.reduce(
      (
        result: Array<FunnelChartTableHeaderDataModel>,
        current: FunnelStepModel,
        index: number
      ) => {
        if (
          current.number === this.localFunnel.steps.length &&
          this.localFunnel.type === FunnelType.USER_CONVERSION
        ) {
          return result;
        }

        return [
          ...result,
          {
            text: this.getLabelForHeader(current.number, chartPercentMode),
            align: "start",
            value: `data.value${index + 1}`,
            width: "180px",
          },
        ];
      },
      []
    );
  }

  getItemsForSteps(
    chartPercentMode: FunnelChartPercentMode
  ): Array<FunnelChartDataTableModel> {
    return this.charts.map((chart: FunnelChartModel) => {
      const steps: Array<Array<number>> = Array.from(chart.stepsValue);
      const stepValues = steps.map(([, value]: Array<number>) => value);

      return {
        name: !this.getChartTitle(chart.breakdownValue)
          ? this.$lang("funnel.chart.total")
          : this.getChartTitle(chart.breakdownValue),
        data: steps.reduce(
          (
            result: Record<string, number>,
            [, value]: Array<number>,
            index: number
          ) => {
            let stepValue;
            const valueIndex = this.isRepeatedConversion ? index + 1 : index;

            if (this.isRepeatedConversion) {
              stepValue = value;
            } else {
              stepValue =
                chartPercentMode === FunnelChartPercentMode.RELATIVE
                  ? calculateRelativePercent(index, value, stepValues)
                  : calculateFirstStepPercent(value, stepValues);
            }

            return {
              ...result,
              [`value${valueIndex}`]: stepValue,
            };
          },
          {}
        ),
      };
    });
  }

  exportToClipboard(delimiter?: string) {
    navigator.clipboard.writeText(this.getCsvContent(delimiter));
  }

  exportToCsvFile() {
    FileUtil.download(
      this.getCsvContent(),
      "Report.csv",
      FileUtil.CONTENT_TYPE_CSV
    );
  }

  exportToTsvFile() {
    FileUtil.download(
      this.getCsvContent("\t"),
      "Report.tsv",
      FileUtil.CONTENT_TYPE_TSV
    );
  }

  updateItemsForExport(items: Array<FunnelChartDataTableModel>) {
    this.itemsForExport = items;
  }

  getCsvContent(delimiter?: string) {
    return stringify(this.itemsForExport, {
      header: true,
      columns: this.csvHeaders,
      delimiter: delimiter,
    });
  }

  getTableCellText(
    item: FunnelChartDataTableModel,
    headerText: string,
    index: number
  ): string {
    if (headerText.includes("Breakdown value")) {
      return item.name;
    }

    const cellValue = item.data[`value${index}`];

    return this.isRepeatedConversion
      ? cellValue.toLocaleString(this.$vuetify.lang.current)
      : `${cellValue}%`;
  }

  getCellGradientStyle(
    headerValue: string,
    item: FunnelChartDataTableModel
  ): string {
    const headerValueAfterPrefix = headerValue.split(".")[1];
    const isExcludedTotal = !this.isRepeatedConversion && item.name === "Total";

    if (!headerValueAfterPrefix || isExcludedTotal) {
      return "";
    }

    const values = this.items.reduce(
      (result: Array<number>, item: FunnelChartDataTableModel) => {
        if (this.isRepeatedConversion || item.name !== "Total") {
          result.push(item.data[headerValueAfterPrefix]);
        }

        return result;
      },
      []
    );

    return `background: ${this.getColor(
      values,
      item.data[headerValueAfterPrefix]
    )};`;
  }

  getColor(values: Array<number>, value: number): string {
    const min = Math.min(...values);
    const max = Math.max(...values);
    const color = ((value - min) / (max - min)) * 120;

    return `hsl(${color}, 100%, ${this.dark ? 25 : 80}%)`;
  }

  getChartTitle(breakdownValue?: Map<FilterId, any>) {
    if (!breakdownValue) {
      return "";
    }

    return Array.from(breakdownValue)
      .map(([id, value]) => {
        if (id === FilterId.SEGMENT) {
          return this.$store.getters.segmentsNameByValue[value] ?? value;
        }

        if (id === FilterId.ATTRIBUTION_DATE_VALUE) {
          return DateUtil.formatDate(value);
        }

        return value;
      })
      .join("_");
  }

  getLabelForHeader(stepNumber: number, mode: string): string {
    const steps = this.localFunnel.steps;

    if (this.isRepeatedConversion) {
      return steps[stepNumber - 1].name ?? "";
    }

    return `${
      mode === FunnelChartPercentMode.RELATIVE
        ? steps[stepNumber - 1].name
        : steps[0].name
    } -> ${steps[stepNumber].name}`;
  }

  details() {
    const routeData = this.$router.resolve({
      name: "funnel_view",
      params: {
        id: this.applicationId,
        funnelId: this.funnelId.toString(),
      },
    });
    window.open(routeData.href, "_blank");
  }

  cancel() {
    this.$router.push({
      name: AppSection.FUNNELS,
      params: { id: this.applicationId },
    });
  }

  generate() {
    if (this.localValue.breakdowns.length) {
      this.groupByPreview = {
        id: FilterPreviewId.GROUP_BY,
        value: this.localValue.breakdowns[0],
      };
    } else {
      this.groupByPreview = null;
    }

    this.isSidebarVisible = false;
    this.$store.dispatch("subscribeOnFunnelChart", this.localValue);
    this.localFilters = this.localValue.filter.map((filter) => filter.clone());
  }

  openSegmentDialog(chart: FunnelChartModel) {
    const breakdownValues = chart.breakdownValue
      ? Array.from(chart.breakdownValue)
      : [];

    const breakdownName =
      breakdownValues.map(([, value]) => value).join("_") ||
      this.$lang("funnel.chart.total");
    const breakdownValueFilters = breakdownValues.map(([id, value]) =>
      singleValueToFilterModel(id, value)
    );

    this.localSegment.filter = this.localFilters.concat(breakdownValueFilters);
    this.createSegmentDialog = {
      value: true,
      steps: Array.from(chart.stepsValue).map(([step]) => step),
      breakdownName: breakdownName,
    };
  }

  cancelSegmentDialog() {
    this.createSegmentDialog = { value: false, steps: [], breakdownName: "" };
    this.localSegment = new CreateFunnelSegmentModel(
      this.applicationId,
      this.funnelId
    );
  }

  createSegment(segment: CreateFunnelSegmentModel) {
    this.localSegment.description =
      this.localSegment.description ||
      this.$lang(
        "funnel.segment.descriptionPattern",
        this.localFunnel.name || "",
        this.createSegmentDialog.breakdownName,
        segment.stepFrom,
        segment.stepTo,
        this.$lang(
          segment.converted
            ? "funnel.segment.conversion.converted"
            : "funnel.segment.conversion.notConverted"
        )
      );
    this.$store.dispatch("createFunnelSegment", segment);
    this.cancelSegmentDialog();
  }

  exportToCsv(
    chartData: Array<Record<string, any>> | Record<string, any>,
    title: string
  ) {
    const data = Array.isArray(chartData)
      ? chartData.map(
          ({ name, value, percent }) => `${name},${value},${percent}%`
        )
      : [`${chartData.name},${chartData.value},${chartData.percent}%`];

    const body = ["step,value,percent", ...data].join("\n");

    FileUtil.download(body, `export-${title}.csv`, "csv");
  }
}
