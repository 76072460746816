


























import { Component, Vue, Prop } from "vue-property-decorator";
import { isBoolean } from "lodash";

import {
  EventParamFilterRangeModel,
  EventParamRangeModel,
  ExcludableMultipleValueFilterModel,
  FilterId,
  FilterModel,
  MultipleValueFilterModel,
} from "@/shared/models";
import IncludedIcon from "@/shared/components/IncludedIcon.vue";

@Component({
  components: {
    IncludedIcon,
  },
})
export default class FunnelStepsPreviewFilter extends Vue {
  @Prop() filter!: FilterModel;
  @Prop({ default: true }) stepsVisible?: FilterModel;

  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }

  get hasParams(): boolean {
    return !!(this.filter as EventParamFilterRangeModel).value?.params?.length;
  }

  get isFilterIncluded(): boolean {
    const { included } = this.filter as ExcludableMultipleValueFilterModel;

    if (isBoolean(included)) {
      return included;
    }

    return true;
  }

  get filterValue(): string {
    if (this.filter.id === FilterId.ACTIVITY_KIND) {
      return (this.filter as MultipleValueFilterModel).values
        .map(
          (value) =>
            this.$store.getters.activityKindNameByValue[value.toLowerCase()]
        )
        .join(", ");
    }

    if (this.filter.id === FilterId.EVENTS_COUNTRY) {
      return (this.filter as ExcludableMultipleValueFilterModel).values.join(
        ", "
      );
    }

    if (
      [FilterId.EVENTS_OS_VERSION, FilterId.EVENTS_APP_VERSION].includes(
        this.filter.id
      )
    ) {
      return (this.filter as MultipleValueFilterModel).values.join(", ");
    }

    const { name } = (this.filter as EventParamFilterRangeModel).value;

    return name ? this.$store.getters.eventNameByValue[name] : "";
  }

  getEventParamValue(eventParam: EventParamRangeModel) {
    const { name, value } = eventParam;
    let returnedValue = `<strong>${name}</strong>`;

    if (value?.operator) {
      returnedValue += ` ${this.$lang(
        `shared.eventName.${(value?.operator as string).toLowerCase()}`
      )} `;
    }

    if (value?.params?.length) {
      returnedValue +=
        value.params.length > 2
          ? value.params.join(", ")
          : value.params.join(` ${this.$lang("shared.and")} `);
    }

    return returnedValue;
  }
}
