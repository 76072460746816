


































































import { Component, Prop, Vue, Ref, Watch } from "vue-property-decorator";
import { debounce } from "lodash";

import { VueForm } from "@/shared/types/ExtendedVueType";
import { AccessType } from "@/shared/models";
import ValidUtil from "@/shared/utils/ValidUtil";
import FunnelModel from "@/funnels/models/FunnelModel";
import { NewFunnelTemplateModel } from "@/templates/models/TemplateModel";

@Component
export default class NewFunnelTemplate extends Vue {
  @Prop() value!: boolean;
  @Prop() funnel!: FunnelModel;
  @Prop() applicationId!: string;

  @Ref("form") readonly form!: VueForm;

  readonly nameRules = [ValidUtil.required(this.$lang("validation.required"))];
  readonly descriptionRules = [
    ValidUtil.maxLength(255, this.$lang("validation.maxLength", 255)),
  ];
  template = new NewFunnelTemplateModel(this.funnel.id);
  isValid = true;

  get isDialogVisible(): boolean {
    return this.value;
  }

  set isDialogVisible(value) {
    this.$emit("input", value);
  }

  get isTemplateNameUnique(): boolean {
    return this.$store.state.templateStore.isTemplateNameUnique;
  }

  get nameErrorMessages(): Array<string> {
    return !this.isTemplateNameUnique
      ? [this.$lang("shared.errors.notUnique")]
      : [];
  }

  get accessTypes(): Array<{ name: string; value: AccessType }> {
    return Object.values(AccessType).map((it) => {
      return {
        name: this.$lang(`accessType.${it.toString().toLowerCase()}`),
        value: it,
      };
    });
  }

  get isTemplateSaving(): boolean {
    return this.$store.state.templateStore.isTemplateSaving;
  }

  @Watch("template.name")
  watchName = debounce(() => {
    this.$store.dispatch("checkTemplateNameUniqueness", this.template.name);
  }, 500);

  mounted() {
    if (this.form) {
      this.form.resetValidation();
    }

    this.$store.commit("setTemplateNameUniqueness", true);
  }

  async saveTemplate() {
    if (!this.form.validate()) {
      return;
    }

    await this.$store
      .dispatch("createFunnelTemplate", this.template)
      .then(() => {
        this.isDialogVisible = false;
      });
  }
}
